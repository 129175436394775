import React from 'react';
import { useNavigate } from 'react-router';

const Modal = ({ isOpen, closeModal, goldLoan }) => {
    const navigate = useNavigate();

    // Function to navigate to the SearchLoan page with the loanId
    const goToDetails = () => {
        if (goldLoan && goldLoan.loanid) {
            const data = { loanid: goldLoan.loanid };
            navigate('/searchloan/', { state: data });
        }
    };
    const goToVoucher = () => {
        if (goldLoan && goldLoan.loanid) {
            const data = { loanid: goldLoan.loanid };
            navigate('/loan-opening-voucher/', { state: data });
        }
    };

    
    const goToKanada = () => {
        if (goldLoan && goldLoan.loanid) {
            const data = { loanid: goldLoan.loanid };
            navigate('/loan-print-kannada/', { state: data });
        }
    };


    return (
        <div className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-3/4">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <button className="absolute top-5 right-5 text-right text-black-900" onClick={closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4 text-center">Gold Loan Details</h3>
                                <div className=''>
                                    <div className='w-full flex justify-end'>
                                        <img src={goldLoan.image} alt="No Image Found" className='h-32 w-32 border text-center text-sm' />
                                    </div>
                                    <div className='overflow-x-auto'>
                                        <table className="w-full divide-y divide-gray-200 rounded">
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Loan ID</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.loanid}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Customer Name</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.full_name}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Address</td>
                                                    <td className="pl-2 text-sm text-gray-700 px-2">
                                                        {goldLoan.address},<br /> {goldLoan.address2}, {goldLoan.city}, {goldLoan.state},
                                                        {goldLoan.country} <br /> {goldLoan.pin}
                                                    </td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Customer Id</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.customerid}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Mobile</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.mobile}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Transaction Date</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.trdate}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Period</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.period}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Amount</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.amount}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Due Date</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.duedate}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Scheme Name</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.scheme_name}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Branch Code</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.brcode}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">ID Collected</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.idcolected}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">User Code</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.ucode}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">ID Number</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.idnumber}</td>
                                                </tr>
                                                <tr className='h-10'>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Pan Card</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.pan}</td>
                                                    <td className="w-1/4 text-sm font-medium text-gray-700">Aadhaar</td>
                                                    <td className="pl-2 text-sm text-gray-700">{goldLoan.adhaar?"Verified":"Not Verified"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4 text-center">Gold Loan Particulars</h3>
                                    <div className='overflow-x-auto'>
                                        <table className="w-full divide-y divide-gray-200 rounded overflow-scroll">
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Items</th>
                                                    <th>Description</th>
                                                    <th>Count</th>
                                                    <th>Weight</th>
                                                    <th>Net Weight</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {goldLoan.gold_details.map((detail, index) => (
                                                    <tr key={detail.id} className='h-10'>
                                                        <td className="w-1/4 text-sm font-medium text-gray-700">{detail.sl_no}</td>
                                                        <td className="pl-2 text-sm text-gray-700">{detail.item}</td>
                                                        <td className="w-1/4 text-sm font-medium text-gray-700">{detail.description}</td>
                                                        <td className="pl-2 text-sm text-gray-700">{detail.count}</td>
                                                        <td className="pl-2 text-sm text-gray-700">{detail.weight}</td>
                                                        <td className="pl-2 text-sm text-gray-700">{detail.ntweight}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className='flex gap-2 justify-center'>

                                        <div className="flex lg:flex-row flex-col justify-center">
                                            <button
                                                type="button"
                                                className="bg-gray-700  hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
                                                onClick={goToDetails}
                                            >
                                                Print Pan Ticket
                                            </button>
                                        </div>
                                        <div className="flex lg:flex-row flex-col justify-center">
                                            <button
                                                type="button"
                                                className="bg-gray-700  hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
                                                onClick={goToVoucher}
                                            >
                                                Print Opening Voucher
                                            </button>
                                        </div>
                                        <div className="flex lg:flex-row flex-col justify-center">
                                            <button
                                                type="button"
                                                className="bg-gray-700  hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
                                                 onClick={goToKanada}
                                            >
                                                Print Kannada
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
