import React from 'react';

const CustomerModal = ({ isOpen, onClose, customer }) => {
    console.log('cus',customer);
    if (!isOpen) return null;
    

    return (
        <div className={`fixed inset-0 z-50 overflow-auto ${isOpen ? 'block' : 'hidden'}`}>
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true" >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-10/12">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <button className="absolute top-5 right-5 text-right text-black-900" onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>

                        </button>
                        <h2 className="text-xl font-bold mb-4 text-center">Customer Details</h2>
                        <div className='w-full '>
                            <div className='w-1/4'>
                                <img src={customer.image}
                                    alt=""
                                    className='h-32 w-32 '
                                />
                            </div>
                            <div className='w-full'>

                                <table className="w-full divide-y divide-gray-200  rounded">
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Customer ID
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.cusid}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Customer Name
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.sal} {customer.fname} {customer.mname} {customer.lname}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4  text-sm font-medium text-gray-700 ">
                                                Date of Birth
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.dob}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Gender
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.gender}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Aadhaar No
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.aadhaar}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Pan No
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.pan}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                {/* Other Id */}
                                                ID Collected
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.othidname}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                {/* Other Id Number */}
                                                ID Number
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.othid}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Occupation
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.occupation}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                House Type
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.ownhouse}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Branch Code
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.brcode}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Email
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.email}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Mobile No
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.mob}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Phone
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.phone}
                                            </td>
                                        </tr>
                                        <tr className='h-10'>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Address
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.address1},<br /> {customer.address2}, {customer.city}, {customer.state},
                                                {customer.country} <br />  {customer.pin}
                                            </td>
                                            <td className="w-1/4 text-sm font-medium text-gray-700 ">
                                                Aadhaar verification
                                            </td>
                                            <td className="pl-2 text-sm text-gray-700">
                                                {customer.aadhaarverified? 'Verified': 'Not Verifeid'}                                             
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerModal;
