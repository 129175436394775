// fingerprint authentication and goldloan details

import React, { useContext, useState } from "react";
import {
  IsDeviceConnected,
  CaptureFinger,
  GetConnectedDeviceList,
  InitDevice,
  GetMorFinAuthInfo,
  GetTemplate,
  VerifyFinger,
} from "./morfinauth";
import { Button } from "@material-tailwind/react";
import { LoanContext } from "../../context/loanContext";
// import AlertMessage from "../../components/alert/Alert";
import { AppContext } from "../../context/appContext";
import axios from "axios";
import LoanDetailTable from "./LoanDetailTable";
import SuccessModal from "../../components/modal/successmodal";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";


function Fingerprint(props) {

  let { formData, setFormData, customerDetail, setCustomerDetail } =
    useContext(LoanContext); // form data

  let { baseurl, brcode, comcode, ucode } = useContext(AppContext);

  let [fin, setFin] = useState();
  let [open, setOpen] = useState(false);
  let [message, setMessage] = useState("");

  // message
  let [verifyMessage, setVerifyMessage] = useState("");
  let [verify, setVerify] = useState(true);
  let [verifyProcess, setVerifyProcess] = useState(false);

  // capture fingerprint from device (mantra)
  let capture = async () => {
    setVerifyProcess(true);
    let list = GetConnectedDeviceList();

    let connectedDvc = list.data.ErrorDescription.split(":");
    connectedDvc = connectedDvc[1].split(",")[0];
    let clientKey = "";
    GetMorFinAuthInfo(connectedDvc, clientKey);

    let result = CaptureFinger(60, 10);

    let template = GetTemplate(0);

    let fp;

    const res1 = await axios.get(`${baseurl}user/userfp_by_id/${ucode}`);

    // fingerprint list of user
    fp = await res1.data.data;

    if (result.httpStaus) {
      setFin(CaptureFinger(60, 10));
    }

    // verifying fingerprint
    for (let i = 0; i < fp.length; i++) {
      // device built-in function to verify fingerprint
      let res = VerifyFinger(template.data.ImgData, fp[i].fp, 0);

      if (res.httpStaus) {
        // breaks the loop when one of the fingerprint matches
        if (res.data.Status) {
          setVerify(false);
          setVerifyProcess(false);
          setFormData({
            ...formData,
            goldloan: { ...formData.goldloan, fpverified: true },
          });
          setVerifyMessage("User Verified.");
          break;
        } else {
          if (res.data.ErrorCode != "0") {
            setVerify(true);
            setVerifyProcess(false);
            setVerifyMessage(res.data.ErrorDescription);
          } else {
            setVerify(true);
            setVerifyProcess(false);
            setVerifyMessage("Finger not matched");
          }
        }
      } else {
        setVerify(true);
        setVerifyProcess(false);
        setVerifyMessage(res.err);
      }
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");

    const mime = "image/jpeg";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onSubmit = async(data) => {
    console.log(data);
    // return
    const formData1 = new FormData();

    formData1.append("amount", data.goldloan.amount);
    formData1.append("duedate", data.goldloan.duedate);
    formData1.append("fk_customerid", data.goldloan.fk_customerid);
    formData1.append("gold_rate", data.goldloan.gold_rate);
    formData1.append("marigin", data.goldloan.marigin);
    formData1.append("natureofloan", data.goldloan.natureofloan);
    formData1.append("scheme", data.goldloan.scheme);
    formData1.append("fk_scheme", data.goldloan.scheme);
    formData1.append("fk_company", 1);
    formData1.append("fk_branch", 1);
    formData1.append(
      "fpverifed",
      data.goldloan.fpverified ? data.goldloan.fpverified : ""
    );
    formData1.append("idcolected", data.goldloan.idcollected);
    formData1.append('idnumber', data.goldloan.idnumber)
    formData1.append("period", data.goldloan.period);
    formData1.append("interest_rate", data.goldloan.interest_rate);

    formData1.append("adhaar", customerDetail.aadhaarverified);

    formData1.append("brcode", brcode);
    formData1.append("comcode", comcode);
    formData1.append("gcode", comcode);
    formData1.append("ucode", ucode);
    formData1.append("customerid", customerDetail.cusid);
    formData1.append("address", customerDetail.address1);
    formData1.append("address2", customerDetail.address2);
    formData1.append("city", customerDetail.city);
    formData1.append("state", customerDetail.state);
    formData1.append("country", customerDetail.country);
    formData1.append("pin", customerDetail.pin);
    formData1.append("full_name", customerDetail.full_name);
    formData1.append("mobile", customerDetail.mob);

    const imageFile =
      formData.goldloan.image &&
      dataURLtoFile(formData.goldloan.image, "image.jpg");
    //   const imageFile = customerDetail.image

    formData.goldloan.image && formData1.append("image", imageFile);

    data.details.forEach((element, index) => {
      formData1.append(`details.${index}.item`, element.item);
      formData1.append(`details.${index}.description`, element.description);
      formData1.append(`details.${index}.count`, element.count);
      formData1.append(`details.${index}.ntweight`, element.ntweight);
      formData1.append(`details.${index}.purity`, element.purity);
      formData1.append(`details.${index}.sl_no`, element.sl_no);
      formData1.append(`details.${index}.weight`, element.weight);
    });
    data.amount.forEach((element, index) => {
      // formData1.append(`amounts.${index}.debit`, element.amount);
      // formData1.append(`amounts.${index}.tr_head`, "element.amount");

      // formData1.append(`amounts.${index}.fk_AccountingHead`, 1);
      // delete element.amount
      Object.entries(element).forEach(([key, value]) => {
        formData1.append(`amounts.${index}.${key}`, value);
        if(key==='mode'){
          if(value.toLowerCase() === 'cash'){
              formData1.append(`amounts.${index}.head_code`, 'Cash');

          }else{
              formData1.append(`amounts.${index}.head_code`, 'Bank');

          }
          // formData1.append(`amounts.${index}.head_code1`, 'Gold Loan');

        }
      });
    });
    //    formData1.append('details', JSON.stringify(formData.details))

    try {
      const response = await axios.post(`${baseurl}goldloan/`, formData1);
  
      if (response.status === 201) {
        setFormData({ goldloan: "", amount: "", details: "" });
        setCustomerDetail({});
        setMessage(response.data);
        setOpen(true);
      }
  
      console.log(response);
    } catch (error) {
      // Handle error case
      if (error.response) {
        // Server responded with a status code outside of the 2xx range
        console.log("Error response:", error.response.data);
        console.log("Status:", error.response.status);
        console.log("Headers:", error.response.headers);
      } else if (error.request) {
        // Request was made but no response was received
        console.log("No response received:", error.request);
      } else {
        // Something else happened while setting up the request
        console.log("Error:", error.message);
      }
    }
  };

  // disabling fingerprint
  const disableFeature = async(key) => {
    try {
      const response = await axios.get(
        `${baseurl}predbvalue/disable-feature/${comcode}/${brcode}/${key}/`
      );
  
      if (response.status === 200) {
        if (response.data.data && response.data.data[0] && !response.data.data[0].verify) {
          setVerify(false);
        }else{
          alert('You do not have access.')
        }
      }
  
      console.log(response);
    } catch (error) {
      console.error("Error fetching feature status:", error);
      console.log(error)
      if(error.response.status === 404){
        alert(error.response.data.message)
      }
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(true); // modal

  // modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="px-2 mt-10">
      {open && message && (
        <SuccessModal
          isOpen={isModalOpen}
          message={message.message}
          data={message.data.loanid}
          onClose={closeModal}
          reload={true}
        />
      )}
      <section className="flex justify-center m-3 ">
        <h1>Customer Details</h1>
      </section>
      <div className="mb-5">
        <LoanDetailTable formData={formData} customerDetail={customerDetail} />
      </div>
      <scection className="flex mt-3">
        <div className="flex justify-center items-center w-52">
          <div className="size-20 border flex justify-center items-center border-black">
            {fin && fin.data.ErrorCode === "0" ? (
              <img
                className="size-16"
                src={`data:image/bmp;base64,${fin.data.BitmapData}`}
              />
            ) : (
              <span className="text-sm">fingerprint</span>
            )}
          </div>
          <div className="flex justify-center items-center">
            <span>{verifyMessage}</span>
          </div>
        </div>
      </scection>
      

      <div className="flex mt-2 ">
        <Button
          className="ms-5"
          size="sm"
          onClick={capture}
          loading={verifyProcess}
        >
          Verify
        </Button>

        <Button
          className="ms-5"
          size="sm"
          onClick={() => disableFeature("fp")}
          //  loading={verifyProcess}
        >
          Disable Fingerprint
        </Button>
      </div>

      <section className="flex justify-end  py-3">
        <Button
          size="sm"
          className={`bg-green-500 hover:bg-green-400 text-white semi-bold py-2 px-2 rounded`}
          onClick={() => onSubmit(formData)}
          disabled={verify}
        >
          Submit
        </Button>
      </section>
    </div>
  );
}

export default Fingerprint;
