import React from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

function Pagination({
  totalItems,
  itemsPerPage,
  activePage,
  setActivePage,
  fetchdata,
}) {
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the range of pages to show
  const getPageNumbers = () => {
    let pages = [];
    const range = 2; // Number of pages to show before and after the current page

    // Add pages before the current page
    for (let i = Math.max(activePage - range, 1); i < activePage; i++) {
      pages.push(i);
    }

    // Add the current page
    pages.push(activePage);

    // Add pages after the current page
    for (
      let i = activePage + 1;
      i <= Math.min(activePage + range, numberOfPages);
      i++
    ) {
      pages.push(i);
    }

    return pages;
  };

  const next = (e) => {
    e.preventDefault();

    if (activePage < numberOfPages) {
      setActivePage(activePage + 1);
    fetchdata &&  fetchdata(activePage + 1);
    }
  };

  const prev = (e) => {
    e.preventDefault();

    if (activePage > 1) {
      setActivePage(activePage - 1);
     fetchdata && fetchdata(activePage - 1);
    }
  };

  const goToFirstPage = (e) => {
    e.preventDefault();

    setActivePage(1);
   fetchdata && fetchdata(1);
  };

  const goToLastPage = (e) => {
    e.preventDefault();


    setActivePage(numberOfPages);
   fetchdata && fetchdata(numberOfPages);
  };

  const pageNumbers = getPageNumbers();

  // Determine if buttons should be visible
  const showFirstPageButton = activePage > 1; // Show "First Page" if the current page is greater than 1
  const showLastPageButton = activePage < numberOfPages; // Show "Last Page" if the current page is less than the last page

  return (
    <div className="flex justify-center mt-10 items-center gap-4">
      {showFirstPageButton && (
        <Button
          variant="text"
          className="flex items-center gap-2"
          onClick={
            goToFirstPage}
        >
          First Page
        </Button>
      )}
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={prev}
        disabled={activePage === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
        {pageNumbers.map((pageNumber) => (
          <IconButton
            key={pageNumber}
            variant={activePage === pageNumber ? "filled" : "text"}
            color="gray"
            onClick={
              fetchdata
                ? (e) => {
                    e.preventDefault();
                    setActivePage(pageNumber);
                    fetchdata(pageNumber);
                  }
                : () => setActivePage(pageNumber)
            }
          >
            {pageNumber}
          </IconButton>
        ))}
      </div>
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={next}
        disabled={activePage === numberOfPages}
      >
        Next <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
      {showLastPageButton && (
        <Button
          variant="text"
          className="flex items-center gap-2"
          onClick={goToLastPage}
        >
          Last Page
        </Button>
      )}
    </div>
  );
}

export default Pagination;
