import React, { useContext, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import DashBoard from "../../components/DashBoard";
import Input from "../../components/Input";
import { Title } from "../../titles/titles";
import { AppContext } from "../../context/appContext";
import DateOfBirthPicker from "../../components/DobInput";
import AlertMessage from "../../components/alert/Alert";
import Webcam from "react-webcam";
import { AadhaarContext } from "../../context/aadhaarContext";
import AadhaarProvider from "../../context/aadhaarContext";
import AadharModal from "../../components/modal/aadharmodal";
import SuccessModal from "../../components/modal/successmodal";
import { Button, Tooltip } from "@material-tailwind/react";
import { useNavigate } from "react-router";

function Customer(props) {

  // button loading when click on submit
  const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setIsOpen] = useState(false); // modal open
  const { aadharDetails, setAadharDetails } = useContext(AadhaarContext); // context for aadhaar details
  const [checkAadhaar, setCheckAadhaar] = useState(false); // state used for address same as aadhaar field
  
  // state used for make aadhaar number field readonly when click verify button
  const [aadhaarReadOnly, setAadhaarReadOnly] = useState(false);
  const [verified, setVerified] = useState(false); // aadhaar verified or not

  const navigate = useNavigate()

  const [toastOpen, setToastOpen] = useState(false)
  const [aadhaarErrorMessage, setAadhaarErrorMessage] = useState('')
  
  const [countryValue, setCountryValue] = useState();
  const [stateValue, setStateValue] = useState();
  const [country_value, setCountry_Value] = useState();
  const [disableImage, setDisableImage] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [houseType, setHouseType] = useState("");
  
  // open success modal
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false); // show image error variable
  const { baseurl, sidebarToggle, comcode, brcode, ucode, gcode, authToken, clearToken } =
    useContext(AppContext);

  const [showOtherIdNumber, setShowOtherIdNumber] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [imageData, setImageData] = useState(null);
  const webcamRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false); // State for showing camera popup

  const[customerId, setCustomerId] = useState('') // state for store customer id after customer creattion

  const url = `${baseurl}customer/register-customer/`;
  const {
    register,
    setError,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCancel = () => {
    setSelectedValue("");
    setShowOtherIdNumber(false);
    setHouseType("");
    reset();
    setImageData(null);
   // window.location.reload()
  };
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setShowOtherIdNumber(false);
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${baseurl}predbvalue/countries/`,
          {
            method: 'GET', // or 'POST', 'PUT', etc. depending on your API
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the authorization header
              'Content-Type': 'application/json' // Optional, depending on your API
            },
        });
        if (response.ok) {
          const data = await response.json();
          setCountries(data.data);
        } else {
          clearToken()
          navigate('/')
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
        clearToken()
        navigate('/')
        console.error("Error fetching countries:", error);
      }
    };
    if (verified) {
      setFieldReadOnly("aadhaar", true); // disabling aadhaar field when aadhaar is verified
      // setAadharDetails(true)
    }
    // else{
    //   setFieldReadOnly("aadhaar", false); 
    //   setAadharDetails(false)
    // }
    //
    // disable fields when aadhaar verified and check same as aadhaar
    if (verified && checkAadhaar) {
      if (aadharDetails && aadharDetails.address) {
        setValue(
          "address1",
          `${aadharDetails.address.house} ${aadharDetails.address.vtc} ${aadharDetails.address.post_office}`
        );
        setValue(
          "address2",
          `${aadharDetails.address.landmark} ${aadharDetails.address.street} ${aadharDetails.address.subdistrict} ${aadharDetails.address.state} ${aadharDetails.address.country}`
        );

        setValue("pin", aadharDetails.address.pincode);
        if(aadharDetails.name){
          const name = aadharDetails.name.split(" ")
          setValue("fname", name[0]);

          // setValue('mname', name[1])
         if (name.length === 3 ) {
         

          setValue("lname", `${name[1]} ${name[2]}`)
          // readonly when check same as aadhaar checkbox
          // setFieldReadOnly("fname", true);
          setFieldReadOnly("lname", true);
        
          }
           else if(name.length === 2){
            setValue("lname", name[1])
           

            setFieldReadOnly("lname", true);
 
          } else if(name.length === 1){
            if(aadharDetails.care_of !== ""){
              setValue("lname", aadharDetails.care_of)

            }else{

              setValue("lname", name[0])
            }
            setFieldReadOnly("lname", false);
          }else{
           
      let concatenatedString = "";

        for (let i = 1; i < name.length; i++) {
              concatenatedString += name[i] + " ";  // Add space between words
              }
            setValue("lname", concatenatedString.trim())
            setFieldReadOnly("lname", true);
          }
          setFieldReadOnly("fname", true);
          setFieldReadOnly("mname", true);




        }
        if(aadharDetails.date_of_birth){

            // Function to convert DD-MM-YYYY to YYYY-MM-DD
            function convertDateFormat(dateString) {
              const [day, month, year] = dateString.split('-');
              return `${year}-${month}-${day}`;
          }

            // Convert the date
            const formattedDate = convertDateFormat(aadharDetails.date_of_birth);

          setValue('dob', formattedDate)
          // readonly when check same as aadhaar checkbox
          setFieldReadOnly("dob", true);
        }

        // finding the country from countries list
        let country_val = countries.find(
          (obj) =>
            obj.country_name.toUpperCase() ===
            aadharDetails.address.country.toUpperCase()
        );
        setCountry_Value(country_val);
        setValue("country", country_val.country_code);
        onSelectCountry(country_val.country_code);

        // let state_val =  states.find(obj => obj.state_name === aadharDetails.address.state);
        // console.log('state', state_val, states)
        // setValue("state", state_val.state_code);
      }
      // disable fields when aadhaar verified and check same as aadhaar
      setFieldReadOnly("address1", true);
      setFieldReadOnly("address2", true);
      setFieldReadOnly("pin", true);
    } else if (!checkAadhaar) {
      setValue("address1", "");
      setValue("address2", "");
      setValue("pin", "");
      setValue("dob", "");
      setValue("fname", "");
      setValue("lname", "");
      setValue("mname", "");
      // enable fields when aadhaar verified and uncheck same as aadhaar
      setFieldReadOnly("address1", false);
      setFieldReadOnly("address2", false);
      setFieldReadOnly("fname", false);
      setFieldReadOnly("lname", false);
      setFieldReadOnly("mname", false);
      setFieldReadOnly("dob", false);
      setFieldReadOnly("pin", false);
      // setFieldReadOnly("address2", false);


    }
    fetchCountries();
  }, [baseurl, checkAadhaar]);

  useEffect(() => {
    if (checkAadhaar) {
      setValue("state", stateValue.state_code);
      onSelectState(stateValue.state_code);
    }
  }, [stateValue]);

  const onSelectCountry = async (countryCode) => {
    try {
      setSelectedCountry(countryCode);
      const response = await fetch(`${baseurl}predbvalue/state/`);
      if (response.ok) {
        const data = await response.json();
        const filteredStates = data.data.filter(
          (state) => state.country_code === countryCode
        );
        // set state value when same as aadhaar field is checked
        if (checkAadhaar) {
          let state_val = filteredStates.find(
            (obj) =>
              obj.state_name.toUpperCase() ===
              aadharDetails.address.state.toUpperCase()
          );
          setStateValue(state_val);
           // setValue("state", 3);
        }
        setStates(filteredStates);
      } else {
        throw new Error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const onSelectState = async (stateCode) => {
    setSelectedState(stateCode);
    try {
      const response = await fetch(`${baseurl}predbvalue/cities/`);
      if (response.ok) {
        const data = await response.json();
        const filteredCities = data.data.filter(
          (city) => city.state_code === stateCode
        );
        setCities(filteredCities);
        console.log("city", data);
      } else {
        throw new Error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const startCamera = () => {
    setShowCamera(true); // Show camera popup
  };

  const closeCamera = () => {
    setShowCamera(false); // Close camera popup
  };

  const capturePicture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageData(imageSrc);
    closeCamera(); // Close camera popup after capturing
    setShowMessage(false)
  };

  const disableCamera = async () => {
    try {
      const cameraResponse = await fetch(
        `${baseurl}predbvalue/disable-feature/cfl/101/cam/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!cameraResponse.ok) {
        throw new Error("Failed to disable camera");
      }

      const data = await cameraResponse.json();

      if (data && data.data && data.data.length > 0) {
        const cameraData = data.data[0];
        if (cameraData.item === "cam" && cameraData.verify === false) {
          setImageData(null);
          setDisableImage(true); // Disable image requirement based on API response
        } else {
          setDisableImage(false); // Enable image requirement based on API response
        }
      } else {
        throw new Error("No data or invalid data structure in response");
      }
    } catch (error) {
      console.log("Error fetching camera status:", error.response);
      // Handle error (e.g., show an error message)
    }
  };
  // same as aadhaar
  const handleAadharCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckAadhaar(true);
    } else {
      setCheckAadhaar(false);
      
    }
  };
  // camera disabl
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Call disableCamera when checkbox is checked
      disableCamera();
      showMessage&&setShowMessage(false) // show image error

    } else {
      setDisableImage(false);

      
    }
  };

  const onSubmit = async (data) => {
    // data.preventDefault(); // Prevent the form from refreshing the page
    // loading
    setIsLoading(true)

    try {
      if (!disableImage && !imageData) {
        setShowMessage(true); // show image error
        setIsLoading(false)
        return;
      }
      const formData = new FormData();
      const imageFile = disableImage
        ? null
        : imageData
        ? dataURLtoFile(imageData, "image.jpg")
        : null;
      formData.append("aadhaar", data.aadhaar);
      formData.append("address1", data.address1);
      formData.append("address2", data.address2);
      formData.append("country", data.country);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("email", data.email);
      formData.append("dob", data.dob);
      formData.append("sal", data.sal);
      formData.append("fname", data.fname);
      formData.append("mname", data.mname);
      formData.append("lname", data.lname);
      formData.append("mob", data.mob);
      formData.append("phone", data.phone);
      formData.append("pan", data.pan);
      formData.append("pin", data.pin);
      formData.append("occupation", data.occupation);
      formData.append("gender", selectedValue);
      formData.append("aadhaarverified", verified);
      if (imageFile !== null) {
        formData.append("image", imageFile);
      }
      formData.append("ownhouse", houseType);
      formData.append("comcode", comcode);
      formData.append("brcode", brcode);
      formData.append("ucode", ucode);
      formData.append("gcode", gcode);
      if (data.othidname) {
        formData.append("othidname", data.othidname);
        formData.append("othid", data.othid);
      }
      if (verified) {
        if(!data.othidname){
        formData.append("othidname", 'aadhaar')
        formData.append("othid", data.aadhaar)
      }
        // if (imageFile !== null) {
        //   formData.append("aadhaar.profile_image", imageFile);
        // }
        formData.append(`aadhaar.verified`, verified);
        formData.append(`aadhaar.aadhaar_image`, aadharDetails.photo);
        formData.append(`aadhaar.mobile`, aadharDetails.mobile_hash);

        formData.append(`aadhaar.aadhaar_number`, data.aadhaar);
        formData.append(`aadhaar.dob`, `${aadharDetails.date_of_birth}`);
        formData.append(
          `aadhaar.full_address`,
          `${aadharDetails.full_address}`
        );
        formData.append(`aadhaar.gender`, `${aadharDetails.gender}`);
        formData.append(`aadhaar.full_name`, `${aadharDetails.name}`);
        formData.append(`aadhaar.landmark`, `${aadharDetails.address.landmark}`)
        formData.append(`aadhaar.street`, `${aadharDetails.address.street}`)
        formData.append(`aadhaar.house`, `${aadharDetails.address.house}`)
        formData.append(`aadhaar.post_office`, `${aadharDetails.address.post_office}`)

        formData.append(`aadhaar.email_hash`, `${aadharDetails.email_hash}`)
        formData.append(`aadhaar.pincode`, `${aadharDetails.address.pincode}`)
        formData.append(`aadhaar.country`, `${aadharDetails.address.country}`)
        formData.append(`aadhaar.state`, `${aadharDetails.address.state}`)
        formData.append(`aadhaar.vtc`, `${aadharDetails.address.vtc}`)
        formData.append(`aadhaar.subdistrict`, `${aadharDetails.address.subdistrict}`)
        formData.append(`aadhaar.district`, `${aadharDetails.address.district}`)
        formData.append(`aadhaar.care_of`, `${aadharDetails.care_of}`)
        formData.append(`aadhaar.year_of_birth`, `${aadharDetails.year_of_birth}`)

        formData.append(
          `aadhaar.address`,
          `${aadharDetails.address.house} ${aadharDetails.address.post_office}  ${aadharDetails.address.district} ${aadharDetails.address.subdistrict} 
          ${aadharDetails.address.vtc} ${aadharDetails.address.street} ${aadharDetails.address.state} ${aadharDetails.address.country} ${aadharDetails.address.pincode}
            ${aadharDetails.address.landmark}`
        );
      }
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      console.log(result)
      if (response.status === 400) {
        // setToastOpen(true)
        //   setAadhaarErrorMessage(result.message)
          console.log(result.errors);
          let error = result.errors || result.errors;
          for (const [key, value] of Object.entries(error)) {
            if(key !== 'brcode' || key !== 'gcode' || key !== 'comcode' || key !== 'ucode'){
              setError(key, {
                type: "server",
                message: value,
              });
            }else{
              break
            }
          }
          setIsLoading(false)
          // return
        }else if(response.status === 500){
          setToastOpen(true)
          setAadhaarErrorMessage(result.message)
        }
     else if (response.status === 201) {
        setCustomerId(result.data.cusid)
        handleCancel();
        setSuccessMessage(result.message);
        setOpen(true); // success modal opening
        setShowOtherIdNumber(false);
        setShowMessage(false); // show image error

      }
      setIsLoading(false)
    } catch (error) {
      console.log("Error:", error);
      // if(error) setIsLoading(false)
      setIsLoading(false)
    }

   
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "Mr.") {
      setValue("gender", "male");
    } else if (event.target.value === "Ms.") {
      setValue("gender", "female");
    }
  };

  const handleSelectionChange = (event) => {
    setHouseType(event.target.value);
  };

  function setFieldReadOnly(fieldName, isReadOnly) {
    // Assuming you have a way to get and set the read-only property of your form fields
    const field = document.querySelector(`[name=${fieldName}]`);
    if (field) {
      field.readOnly = isReadOnly;
    }
  }

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = "image/jpeg";
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div>
      <DashBoard />
      {/* success modal */}
      {successMessage && open && ( 
        <SuccessModal
          isOpen={open}
          message={successMessage} // success message
          onClose={closeModal}
         reload={true} // page reload when click ok
        data={customerId}
        />
      )}
{/* end of success modal */}



{/* aadhaar number verify and aadhaar details modal */}
      <AadharModal
        verified={verified} // used for verify aadhaar details
        setVerified={setVerified}
        setIsOpen={setIsOpen}
        aadhaar={watch("aadhaar")} // passing aadhaar number to modal
        isOpen={isOpen}
        aadharDetails={aadharDetails} // for storing aadhaar details after click accept
        setAadharDetails={setAadharDetails}
        setCheckAadhaar={setCheckAadhaar}
      />
{/* end of aadhaar modal */}

      <div
        className={`${
          !sidebarToggle ? "ms-64 duration-500" : "ms-0 duration-500"
        }`}
      >
        <div className="flex justify-center bg-white md:p-8 rounded-lg w-full">
          <form
            className="md:border md w-full py-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Title title="Customer" />

            {/* {successMessage && open && (
              <AlertMessage
                open={open}
                setOpen={setOpen}
                message={successMessage}
              />
            )} */}

            {/* Button to access camera */}
            <div className="flex flex-wrap justify-between md:w-full">
              <div className="w-full px-2 mb-4 md:w-1/3 md:order-2">
              
                {/* Input components */}
                <div className="mb-4">
                  <Input
                    style={{ textAlign: "left" }}
                    type="text"
                    name="fname"
                    label="First Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "This field is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                    required
                  />
                </div>
                <div className="mb-4">
                  <Input
                    style={{ textAlign: "left" }}
                    type="text"
                    name="lname"
                    label="Last Name"
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: "This field is required",
                    }}
                    required
                  />
                </div>
                <div>
                  <DateOfBirthPicker
                    style={{ textAlign: "left" }}
                    name="dob"
                    label="Date of Birth"
                    errors={errors}
                    register={register}
                    required
                  />
                  {errors.dob && (
                    <p className="text-red-500 text-xs italic">
                      Please select your Date of birth
                    </p>
                  )}
                </div>
              </div>

              <div className="w-full md:w-1/3 px-2 mb-4 md:order-3">
                {/* Input component */}
                <div className="mb-4">
                  <Input
                    style={{ textAlign: "left" }}
                    type="text"
                    name="mname"
                    label="Middle Name(Optional)"
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="mb-4">
                  <label>Salutation</label>
                  <select
                    {...register("sal", { required: true })}
                    id="sal"
                    name="sal"
                    label="Salutation"
                    className="w-full h-7 border  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                    defaultValue="Choose"
                    onChange={handleChange}
                  >
                    <option value="">Select Salutation</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                  </select>
                  {errors.sal && (
                    <p className="text-red-500 text-xs italic">
                      This field is required
                    </p>
                  )}
                </div>
                <div>
                  <Input
                    style={{ textAlign: "left" }}
                    type="text"
                    name="occupation"
                    label="Occupation (Optional)"
                    errors={errors}
                    register={register}
                    
                  />
                </div>
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 flex flex-col items-center md:order-1">
                {/* Box for captured image preview */}
                <div className="w-36 h-40 mb-2 border border-gray-300 rounded overflow-hidden">
                  {imageData ? (
                    <img
                      src={imageData}
                      alt="Captured"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-400">
                      No Image
                    </div>
                  )}
                </div>

                {/* "Access Camera" button */}
                <button
                  type="button"
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 w-36 h-10 mb-2"
                  onClick={startCamera}
                >
                  Access Camera
                </button>

                <div>
                  <label>Disable camera</label>
                  <Tooltip content="Click here to disable the camera." placement="top">

                  <input
                    className="ml-3"
                    type="checkbox"
                    onClick={handleCheckboxChange}
                  />
                  </Tooltip>
                </div>
                {showMessage && (
        <p className="text-red-500 mt-2 italic">Image is required</p>
      )}
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="hidden w-full md:w-1/3 px-2 mb-4 lg:mt-8 mt-6">
                <label>
                  <input
                    className=""
                    type="radio"
                    {...register("gender", { required: true })}
                    value="male"
                    checked={watch("gender") === "male"}
                    onChange={handleChange}
                  />{" "}
                  Male
                  <input
                    className="ml-10"
                    type="radio"
                    {...register("gender", { required: true })}
                    value="female"
                    checked={watch("gender") === "female"}
                    onChange={handleChange}
                  />{" "}
                  Female
                </label>
                {errors.gender && (
                  <p className="text-red-500 text-xs italic">
                    Please select a gender
                  </p>
                )}
              </div>

              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none flex">
                <Input
                  style={{ textAlign: "left" }}
                  type="number"
                  name="aadhaar"
                  label="Aadhaar"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                    maxLength: {
                      value: 12,
                      message: "Aadhaar should contain exactly 12 numbers",
                    },
                    minLength: {
                      value: 12,
                      message: "Aadhaar should contain exactly 12 numbers",
                    },
                    // disabled: { aadhaarReadOnly },
                  }}
                  required
                />
                {String(watch('aadhaar')).length === 12 && 
                (<button
                  type="button"
                  onClick={() => {
                    setIsOpen(true);
                    setAadhaarReadOnly(true);
                  }}
                  disabled={verified}
                  className={`${
                    verified ? "text-green-600" : "text-blue-700"
                  } font-semibold w-20 text-center h-8 mt-6 rounded`}
                >
                  {verified ? "Verified" : "Verify"}
                </button>)}
              </div>

              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none">
                <Input
                  style={{ textAlign: "left" }}
                  type="text"
                  name="pan"
                  label="Pan Card (Optional)"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    // required: "This field is required",
                    pattern: {
                      value: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
                      message: "Wrong PAN Number format",
                    },
                  }}
                  // required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <label>Other Id{verified&&<>(Optional)</>}</label>
                <select
                  {...register("othidname", {required: !verified&&true})}
                  className="form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                  defaultValue=""
                  onClick={() =>
                    setShowOtherIdNumber(watch("othidname") !== "")
                  }
                  disabled={verified} // disabling when aadhaar is verified
                >
                  <option value="">Choose</option>
                  <option value="Driving License">Driving License</option>
                  <option value="Voter Id">Voter Id</option>
                  <option value="Other id">Other</option>
                </select>
                {errors.othidname && !verified && (
                  <p className="text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
              {/* Conditionally render Other Id Number input field */}
              {showOtherIdNumber && (
                <div className="w-full md:w-1/3 px-2 mb-4">
                  <label>Other Id Number</label>
                  <input
                    type="text"
                    className="form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.5 mt-1 rounded"
                    {...register("othid", { required: true })}
                    disabled={verified} // disabling when aadhaar is verified
                  />
                  {errors.othid && (
                    <p className="text-red-500 text-xs italic">
                      This field is required
                    </p>
                  )}
                </div>
              )}
              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none">
                <Input
                  style={{ textAlign: "left" }}
                  type="number"
                  name="mob"
                  label="Mobile Number"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none">
                <Input
                  style={{ textAlign: "left" }}
                  type="number"
                  name="phone"
                  label="LAN Phone(Optional)"
                  errors={errors}
                  register={register}
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <Input
                  style={{ textAlign: "left" }}
                  type="email"
                  name="email"
                  label="Email (Optional)"
                  errors={errors}
                  register={register}
                  // validationSchema={{
                  //     required: "This field is required"
                  // }}
                  // required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 lg:mt-8 mt-6">
                <label>
                  <input
                    type="radio"
                    {...register("ownhouse", { required: true })}
                    value="ownhouse"
                    checked={houseType === "ownhouse"}
                    onChange={handleSelectionChange}
                  />{" "}
                  Own House
                  <input
                    className="ml-10"
                    type="radio"
                    {...register("ownhouse", { required: true })}
                    value="rentedhouse"
                    checked={houseType === "rentedhouse"}
                    onChange={handleSelectionChange}
                  />{" "}
                  Rented House
                </label>
                {errors.ownhouse && (
                  <p className="text-red-500 text-xs italic">
                    Please select your house type
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                {/* <label>Address Same as Aadhaar</label> */}
                <label>Same as Aadhaar</label>

                <input
                  className="ml-3"
                  type="checkbox"
                  checked={checkAadhaar}
                  onClick={handleAadharCheckboxChange}
                  disabled
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <Input
                  style={{ textAlign: "left" }}
                  type="text"
                  name="address1"
                  label="Address(Building No./House No./etc.)"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                    // maxLength: {
                    //   value: 50,
                    //   message: "Only contain maximum of 50 characters",
                    // },
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <Input
                  style={{ textAlign: "left" }}
                  type="text"
                  name="address2"
                  label="Address(Landmark/Road/etc.)"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                    // maxLength: {
                    //   value: 50,
                    //   message: "Only contain maximum of 50 characters",
                    // },
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <label>Country</label>
                <select
                  disabled={checkAadhaar && verified} // disable when check on same as aadhaar
                  value={countryValue}
                  {...register("country", { required: true })}
                  className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                  onChange={(e) => onSelectCountry(e.target.value)} // Pass selected country code
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option
                      key={country.country_code}
                      value={country.country_code}
                    >
                      {country.country_code} - {country.country_name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <label>State</label>
                <select
                  // value={stateValue}
                  disabled={checkAadhaar && verified}
                  {...register("state", { required: true })}
                  className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                  onChange={(e) => onSelectState(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states
                    .filter((state) => state.country_code === selectedCountry)
                    .map((state) => (
                      <option key={state.state_code} value={state.state_code}>
                        {state.state_code} - {state.state_name}
                      </option>
                    ))}
                </select>
                {errors.state && (
                  <p className="text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4">
                <label>City</label>
                <select
                  {...register("city", { required: true })}
                  className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                >
                  <option value="">Select City</option>
                  {cities
                    .filter(
                      (city) =>
                        city.country_code === selectedCountry &&
                        city.state_code === selectedState
                    )
                    .map((city) => (
                      <option key={city.city_code} value={city.city_code}>
                        {city.city_code} - {city.city_name}
                      </option>
                    ))}
                </select>
                {errors.city && (
                  <p className="text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none">
                <Input
                  style={{ textAlign: "left" }}
                  type="number"
                  name="pin"
                  label="Pin Code"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full md:w-1/3 px-2 mb-4 arrow_none">
                <Input
                  style={{ textAlign: "left" }}
                  type="text"
                  name="description"
                  label="Description (optional)"
                  errors={errors}
                  register={register}
                />
              </div>
              {showCamera && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                  <div className="bg-white p-4 rounded-lg w-2/4 h-96">
                    <h2 className="text-lg font-semibold mb-2">Camera</h2>
                    <div className="flex justify-end">
                      <button
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                        onClick={closeCamera}
                      >
                        Close
                      </button>
                    </div>
                    <Webcam
                      className="h-52"
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/png"
                    />
                    <div className="flex justify-center mt-4">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={capturePicture}
                      >
                        Capture Picture
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {aadhaarErrorMessage && toastOpen && (
            <AlertMessage
            color='red'
              open={toastOpen}
              setOpen={setToastOpen}
              message={aadhaarErrorMessage}
            />
          )}
            <div className="md:flex justify-end pr-10 sm: ml-10 ">
              <div className="flex justify-center mt-5  md:pr-10">
                <Button
                 loading={isLoading}
                  type="submit"
                  className="w-20 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </Button>
                
              </div>
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-20 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
            {/* {console.log(verified)}
            {console.log(checkAadhaar, "data")}
            {console.log(aadharDetails, "aadhaar")} */}
          </form>
        </div>
      </div>
    </div>
  );
}
export default Customer;
