import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
// import { Checkbox } from "@material-tailwind/react";
import { LoanContext } from "../../context/loanContext";
import AutoComplete from "../../components/autocomplete/AutoComplete";
import { AppContext } from "../../context/appContext";
import Webcam from "react-webcam";
import { Button } from "@material-tailwind/react";
import "./../goldloan/goldloan.css";
import { useNavigate } from "react-router";
// import SuccessModal from '../../components/modal/successmodal';

function GoldLoanAppForm({ step }) {
  // const [country, setCountry] = React.useState(0);

  let {
    formData,
    setFormData,
    natureOfLoan,
    customerDetail,
    setCustomerDetail,
  } = useContext(LoanContext);
  let { baseurl, brcode, comcode, authToken, clearToken } =
    useContext(AppContext);

  const [tempCusDetail, setTempCusDetail] = useState({}); // storing customer details temporary

  const navigate = useNavigate();
  let [customer, setCustomer] = useState([]);

  let [text, setText] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [searchOption, setSearchOption] = useState([
    "Choose",
    "Name",
    "CustId",
    "Aadhaar",
    "Mobile",
  ]);
  const [option, setOption] = useState(0);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [checkbox, setCheckBox] = useState(); // state for check box

  const opt = searchOption[option];
  const searchInput = "flex items-center";

  const handleChangeAddress = (e) => {
    setCustomerDetail({ ...customerDetail, [e.target.name]: [e.target.value] });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      goldloan: { ...formData.goldloan, [e.target.name]: e.target.value },
    });
  };

  const handleChecked = (e) => {
    // setFormData({
    //   ...formData,
    //   goldloan: { ...formData.goldloan, adhaar: e.target.checked },
    // });
    if (e.target.checked) {
      setCheckBox(true);
      setCustomerDetail({ ...tempCusDetail });
    } else {
      // clearing details when unchecked
      setCustomerDetail({
        ...customerDetail,
        address2: "",
        address1: "",
        state: "",
        country: "",
        city: "",
        pin: "",
        othid: "",
        othidname: "",
        // aadhaarverified: false
      });
      setCities([]);
      setStates([]);
      setCheckBox(false);
    }
  };

  const handleChange1 = (e) => {
    setShowOption(true);
    setText(e.target.value);
    e.target.value.length === 0 && setCustomerDetail({});

    fetch(
      `${baseurl}customer/search-customer/${comcode}/${brcode}/?customer_name=${e.target.value}`
    )
      .then((response) => {
        if (!response.ok) {
          setCustomer([]);
          setShowOption(false);
        }
        return response.json();
      })
      .then((response) => {
        console.log("customer", response.data);
        setCustomer(response.data);
      });
  };

  const {
    register,
    setValue,
    // handleSubmit,
    formState: { errors },
  } = useForm();

  const classes =
    "form-control mt-1 flex justify-between ps-2 w-full md:w-1/2 flex-col";
  const section_class = "flex justify-between flex-col md:flex-row";

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${baseurl}predbvalue/countries/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json", // Specify the content type
          Authorization: `Bearer ${authToken}`, // Authorization header
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCountries(data.data);
      } else {
        throw new Error("Failed to fetch countries");
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
      clearToken();
      navigate("/");
      console.error("Error fetching countries:", error);
    }
  };

  const onSelectCountry = async () => {
    try {
      const response = await fetch(
        `${baseurl}predbvalue/state-list/${customerDetail.country}`
      );
      if (response.ok) {
        const data = await response.json();
        console.log("in select cointry", data.data);
        const filteredStates = data.data;
        setStates(filteredStates);
      } else {
        setStates([]);
        throw new Error("Failed to fetch states");
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const onSelectState = async () => {
    try {
      const response = await fetch(
        `${baseurl}predbvalue/city-list/${customerDetail.country}/${customerDetail.state}`
      );
      if (response.ok) {
        const data = await response.json();
        const filteredCities = data.data;
        setCities(filteredCities);
      } else {
        setCities([]);
        throw new Error("Failed to fetch cities");
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // function for set initail value for state checkbox
  const initial_value_checkbox = () => {
    tempCusDetail.aadhaarverified ? setCheckBox(true) : setCheckBox(false);
    setTempCusDetail({ ...tempCusDetail, aadhaarverified: null });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      goldloan: {
        ...formData.goldloan,
        fk_customerid: customerDetail.id && customerDetail.id,
        // adhaar: customerDetail.aadhaarverified,
        pan: customerDetail.pan && customerDetail.pan,
        idcollected: customerDetail.othidname,
        idnumber: customerDetail.othid,
      },
    });
    fetchCountries();
    customerDetail.state && onSelectState();
    customerDetail.country && onSelectCountry();

    // initial_value_checkbox()
    // customerDetail.aadhaarverified && setCheckBox(true)
  }, [customerDetail]);

  const [imageData, setImageData] = useState(null);
  const webcamRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false); // State for showing camera popup
  const startCamera = () => {
    setShowCamera(true); // Show camera popup
  };

  const closeCamera = () => {
    setShowCamera(false); // Close camera popup
  };

  const capturePicture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageData(imageSrc);
    setCustomerDetail({ ...customerDetail, image: imageSrc });
    setFormData({
      ...formData,
      goldloan: { ...formData.goldloan, image: imageSrc },
    });
    closeCamera(); // Close camera popup after capturing
  };

  const customer_search = async (key, value) => {
    try {
      const response = await fetch(
        `${baseurl}customer/search-customer/${comcode}/?${key}=${value}`
      );

      // if (!response.ok) {
      //   throw new Error(`HTTP error! Status: ${response.status}`); // Handle non-2xx responses
      // }

      const data = await response.json();


      if (data.data) {
        setCheckBox(data.data.aadhaarverified);
        setCustomerDetail(data.data); // Update the state with customer details
        setTempCusDetail(data.data);
      } else {
        // console.log("No customer found.");
        alert("Customer Not Found ");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error); // Handle errors
    }
  };

  let [search, setSearch] = useState({});
  const handleSearchOnChange = (e) => {
    setSearch({ [e.target.name]: e.target.value });
  };
  const [error, setError] = useState("");

  // Validation function
  const validateInput = (value) => {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    if (regex.test(value)) {
      setError(""); // Clear error if the input is valid
    } else {
      setError("Invalid format.");
      console.log(
        "Invalid format. It should be 5 letters, 4 digits, and 1 letter"
      );
    }
  }; // Handle input change
  const handleInputChange = (event) => {
    const value = event.target.value;
    validateInput(value);
    setCustomerDetail({ ...customerDetail, [event.target.name]: value });
  };

  return (
    <div className="px-2 border pb-4 mt-10">
      {console.log(customerDetail, formData)}

      <section className={`flex justify-around flex-col md:flex-row mb-5 mt-2`}>
        <div className="flex">
          <input
            type="text"
            value={search.customer_id}
            onChange={handleSearchOnChange}
            name="customer_id"
            className="form-control   
                             w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            placeholder="Customer Id"
          />

          <Button
            className="ms-2"
            size="sm"
            onClick={() => customer_search("customer_id", search.customer_id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </Button>
        </div>

        <div className="flex">
          <input
            type="text"
            value={search.aadhaar}
            name="aadhaar"
            onChange={handleSearchOnChange}
            className="form-control   
                             w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            placeholder="Aadhaar"
          />
          <Button
            className="ms-2"
            size="sm"
            onClick={() => customer_search("aadhaar", search.aadhaar)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </Button>
        </div>
        <div className={searchInput}>
          <AutoComplete
            text={text}
            setText={setText}
            options={customer}
            handleChange={handleChange1}
            showOption={showOption}
            setCheckBox={setCheckBox} // set initial value for state checkbox
            setCustomerDetail={setCustomerDetail}
            setTempCusDetail={setTempCusDetail}
            setShowOption={setShowOption}
            setValue={setValue}
            setFormData={setFormData}
            formData={formData}
          />
        </div>
      </section>
      <section className="flex">
        {/* <div className="w-full border md:w-1/3 px-2 mb-4 flex flex-col items-center md:order-1"> */}
        {/* Box for captured image preview */}
        <div>
          <div className="w-36 h-40 mb-2 border border-gray-300 rounded overflow-hidden">
            {customerDetail.image ? (
              <img
                src={customerDetail.image}
                alt="Captured"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-400">
                No Image
              </div>
            )}
          </div>

          {/* "Access Camera" button */}
          <button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-36 h-10 mb-2"
            onClick={() => {
              startCamera();
              setCustomerDetail({ ...customerDetail, image: "" });
            }}
          >
            Access Camera
          </button>
        </div>
        <section>
          <section className={section_class}>
            {/* <div className={classes}> */}

            {/* </div> */}
            <div className={classes}>
              <label>Name of Applicant</label>

              <input
                type="text"
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                name="name"
                value={customerDetail.full_name && customerDetail.full_name}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className={classes}>
              <label>Customer Id</label>

              <input
                type="text"
                className="form-control w-full px-3 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                name="cusid"
                value={customerDetail.cusid && customerDetail.cusid}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className={classes}>
              <div className="flex justify-between">
                <label>Aadhaar</label>

                {customerDetail.aadhaarverified === true ? (
                  <label className="text-green-600 text-sm">Verified</label>
                ) : "aadhaarverified" in customerDetail ? (
                  <label className="text-red-600 text-sm">Not Verified</label>
                ) : (
                  ""
                )}
              </div>

              <input
                type="text"
                className="form-control w-full px-3 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                name="pan"
                value={customerDetail.aadhaar && customerDetail.aadhaar}
                onChange={handleInputChange}
                readOnly
              />
              <span style={{ color: "red" }}>{error && error}</span>
            </div>
            <div className={classes}>
              <label>PAN</label>

              <input
                type="text"
                className="form-control w-full px-3 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                name="pan"
                value={customerDetail.pan && customerDetail.pan}
                onChange={handleInputChange}
                // readOnly={formData.goldloan.adhaar}
              />
              <span style={{ color: "red" }}>{error && error}</span>
            </div>

            <div className={classes} style={{ maxHeight: "26px" }}>
              <label>Mobile Number</label>

              <input
                type="text"
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                name="mobile"
                value={customerDetail.mob && customerDetail.mob}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className={classes}>
              <input
                type="checkbox"
                name="adhaar"
                checked={checkbox}
                // value={}
                disabled={!customerDetail.aadhaarverified}
                onChange={handleChecked}
              />
              Address As Aadhaar
            </div>
          </section>
          <section className={section_class}>
            {/* <div className={classes}>
              <label>ID Collected</label>
              <input
                type="text"
                name="idcollected"
                value={customerDetail.othidname}
                // onChange={handleChange}
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                // required
                readOnly={checkbox}
              />
            </div> */}
            <div className={classes}>
              <label>ID Collected</label>

              <select
                value={
                  (customerDetail.othidname && customerDetail.othidname)
                
                }
                {...register("othidname", { required: true })}
                className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                onChange={(e) =>
                  setCustomerDetail({
                    ...customerDetail,
                    [e.target.name]: e.target.value,
                  })
                }
                disabled={checkbox}
              >
                <option value="">Choose</option>
                <option value="aadhaar">Aadhaar</option>
                <option value="Driving License">Driving License</option>
                <option value="Voter Id">Voter Id</option>
                <option value="Other Id">Other</option>
              </select>
              {errors.country && (
                <p className="text-red-500 text-xs italic">
                  This field is required
                </p>
              )}
            </div>
            <div className={classes}>
              <label>ID Number</label>
              <input
                type="text"
                name="othid"
                value={customerDetail.othid}
                onChange={handleChangeAddress}
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                // required
                // readOnly={formData.goldloan.adhaar}
                readOnly={checkbox}
              />
            </div>
            <div className={classes}>
              <label>Address(Building No./House No.)</label>
              <input
                type="text"
                name="address1"
                value={customerDetail.address1}
                onChange={handleChangeAddress}
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                required
                readOnly={checkbox}
              />
            </div>
            <div className={classes}>
              <label>Address(Landmark/Road)</label>
              <input
                value={customerDetail.address2}
                type="text"
                name="address2"
                className="form-control  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                onChange={handleChangeAddress}
                readOnly={checkbox}
                required
              />
            </div>
          </section>

          <section className={section_class}></section>
          <section className={section_class}>
            <div className={classes}>
              <label>Country</label>

              <select
                value={customerDetail.country && customerDetail.country}
                {...register("country", { required: true })}
                className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                onChange={(e) =>
                  setCustomerDetail({
                    ...customerDetail,
                    [e.target.name]: e.target.value,
                  })
                } // Pass selected country code
                disabled={checkbox}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option
                    key={`country${country.country_code}`}
                    value={country.country_code}
                  >
                    {country.country_code} - {country.country_name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <p className="text-red-500 text-xs italic">
                  This field is required
                </p>
              )}
            </div>
            <div className={classes}>
              <label>State</label>

              <select
                value={customerDetail.state && customerDetail.state}
                {...register("state", { required: true })}
                className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                onChange={(e) =>
                  setCustomerDetail({
                    ...customerDetail,
                    state: e.target.value,
                  })
                }
                disabled={checkbox}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option
                    key={`state${state.state_code}`}
                    value={state.state_code}
                  >
                    {state.state_code} - {state.state_name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <p className="text-red-500 text-xs italic">
                  This field is required
                </p>
              )}
            </div>
            <div className={classes}>
              <label>City</label>

              <select
                {...register("city", { required: true })}
                value={customerDetail.city && customerDetail.city}
                className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1 rounded"
                onChange={(e) =>
                  setCustomerDetail({
                    ...customerDetail,
                    city: e.target.value,
                  })
                }
                disabled={checkbox}
              >
                <option value="">Select City</option>
                {cities &&
                  cities.map((city, index) => (
                    <option key={`c${index}`} value={city.city_code}>
                      {city.city_code} - {city.city_name}
                    </option>
                  ))}
              </select>
              {errors.city && (
                <p className="text-red-500 text-xs italic">
                  This field is required
                </p>
              )}
            </div>
            <div className={classes}>
              <label>Pin Code</label>
              <input
                value={customerDetail.pin && customerDetail.pin}
                className="form-control no-spinner  w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                type="number"
                name="pin"
                label="Pin Code"
                onChange={handleChangeAddress}
                required
                readOnly={checkbox}
              />
            </div>
            <div className={classes}>
              <label>Nature of Loan</label>

              <select
                value={formData.goldloan.natureofloan}
                onChange={handleChange}
                id="sal"
                name="natureofloan"
                label=""
                className="w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
                defaultValue="Choose"
                readOnly
              >
                <option selected value="">
                  Choose
                </option>

                {natureOfLoan &&
                  natureOfLoan.map((data, index) => {
                    return (
                      <option key={index} value={data.loan_name}>
                        {data.loan_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </section>

          <section className={section_class}></section>
        </section>
      </section>
      {showCamera && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-lg w-96">
            <h2 className="text-lg font-semibold mb-2">Camera</h2>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                onClick={closeCamera}
              >
                Close
              </button>
            </div>
            {/* webcam */}
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              width={280}
              height={200}
            />

            <div className="flex justify-center mt-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={capturePicture}
              >
                Capture Picture
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GoldLoanAppForm;
