import React, { useState } from "react";

const getOptionClass = (index, length) => {
  let className = "hover:bg-gray-500 border px-4 py-2";
  if (index == 0) className += "rounded-t-lg";
  else if (index == length - 1) className += "rounded-b-lg";
  return className;
};
function AutoComplete({
  options,
  text,
  setText,
  handleChange,
  setShowOption,
  showOption,
  setCustomerDetail,
  setFormData,
  formData,
  setTempCusDetail,
  setCheckBox,
}) {
  const select = (option) => {
    setText("");
    setFormData({...formData, goldloan:{...formData.goldloan}})
    setCustomerDetail((prev) => ({ ...prev, ...option }));
    setTempCusDetail((prev) => ({ ...prev, ...option }))
    setCheckBox(option.aadhaarverified)

    setShowOption(false);
  };
  // options.length === 0 && setCustomerDetail({})
  return (
    <div className="relative w-56">
      <input
        type="text"
        placeholder="Search"
        value={text}
        onChange={handleChange}
        className="border-2 border-gray-300 w-full focus:border-gray-400 rounded px-4 outline-none"
      />
      {showOption && (
        <ul className="absolute hover:shadow-xl shadow-lg bg-gray-100 w-full h-56 overflow-scroll rounded-lg">
          {options &&
            options.map((option, index) => (
              <li
                key={`e${index}`}
                className={getOptionClass(index, options.length)}
                onClick={(e) => select(option)}
              >
                {option.full_name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default AutoComplete;
