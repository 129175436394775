import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
// import Modal from "./goldmodal"; // Assuming the Modal component file is in the same directory
import { AppContext } from "../../context/appContext";
import Pagination from "../../components/Pagination";
import DashBoard from "../../components/DashBoard";
import ApprovalModal from "../../components/ApprovalModal";

const ApprovalGoldLoanList = () => {
  const today = new Date().toISOString().split("T")[0];
  const [goldLoans, setGoldLoans] = useState([]);
  const [selectedGoldLoan, setSelectedGoldLoan] = useState(null);
  const { baseurl, comcode, brcode } = useContext(AppContext);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const itemsPerPage = 15;
  const [status, setStatus] = useState("n");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [loading, setLoading] = useState(false);

  const now = new Date();
    const date = now.toISOString().split('T')[0];
    const time = now.toTimeString().split(' ')[0];
    const approvedtime = `${date} ${time}`;

  const [showModal, setShowModal] = useState(false);
    const [approverId, setApproverId] = useState('');
    const closeApproveModal = () => {
      setShowModal(false);
      setSelectedGoldLoan(null)
  };

    const confirmApprove = async (approverId) => {
        
      const approvalData = {
          approvedby: approverId,
         
      };
      try {
        await axios.post(`${baseurl}/goldloan/add-approval-goldloan/${comcode}/${brcode}/${selectedGoldLoan.id}/`, approvalData);
        // setGoldLoans(goldLoans.map(goldLoan => {
        //     if (goldLoan.id === selectedGoldLoan.id) {
        //       console.log('im')
        //         return { ...goldLoan, approved: true, approvedby: approverId, approvedtime: approvedtime };
        //     }
        //     return goldLoan;
        // }));
        fetchGoldLoans()
    } catch (error) {
        console.error('Error approving goldloan:', error);
    }
    setShowModal(false);
    }

  //   useEffect(() => {
  //     fetchGoldLoans();
  //   }, [currentPage]);

  const fetchGoldLoans = async () => {
    // e.preventDefault()
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseurl}goldloan/get-goldloan-approval/${comcode}/${brcode}/`,
        {
          params: {
            page: currentPage,
           
            start_date: startDate,
            end_date: endDate,
          },
        }
      );

      setGoldLoans(response.data.results.data); // Assuming your API response structure
      console.log(response);

      setTotalItems(response.data.count);
    } catch (error) {
      console.log("Error fetching data: ", error);
      if (error.response.status === 404) {
        console.log(error.response.data.errors);
        setError(error.response.data.errors);
      }
    }
    setLoading(false);
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    return currentDate;
  };
const [isModal,setIsModal] = useState(false)
  const openModal = (goldLoan) => {
    setSelectedGoldLoan(goldLoan);
    setIsModal(true)
  };

  const closeModal = () => {
    setSelectedGoldLoan(null);

    setIsModal(false)
  };
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <DashBoard />
      <div className="container mx-auto px-4 py-8 pt-20">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Gold Loan Approval
        </h2>

        <div className="right-0 text-bold">
          Total Number of Loans: {totalItems}
        </div>

        <div className="overflow-x-auto">
          <div className="overflow-y-auto">
            <div className="flex gap-2">
              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">Start Date:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  max={getCurrentDate()}
                  className="border rounded px-2 border-black w-full"
                />
              </div>

              <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
                <label className="mb-1 text-sm">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={getCurrentDate()}
                  min={startDate}
                  className="border rounded px-2 border-black w-full"
                />
              </div>
              <div className="flex flex-wrap w-full mt-5">
                <button
                  onClick={fetchGoldLoans}
                  type="submit"
                  className="bg-gray-500 text-white px-4 py-1 h-8 rounded-md hover:bg-gray-600 w-full sm:w-auto"
                >
                  Submit
                </button>
              </div>
            </div>
            {loading && <div className="spinner"></div>} {/* loading spinner */}
            <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
              <thead className="bg-gray-100">
                <tr>
                  <td className="px-4 py-1 text-left">Loan ID</td>
                  <td className="px-4 py-1 text-left">Full Name</td>
                  <td className="px-4 py-1 text-left">Amount</td>
                  <td className="px-4 py-1 text-left">Loan Date</td>
                  <td className="px-4 py-1 text-left">Due Date</td>
                  <td className="px-4 py-1 text-left">Gold Rate</td>
                  <td className="px-4 py-1 text-left">Customer ID</td>
                  <td className="px-4 py-1 text-left">FP Verified</td>
                  <td className="px-4 py-1 text-left">U-Code</td>
                </tr>
              </thead>
              <p>{error}</p>
              <tbody>
                {goldLoans.map((goldLoan, index) => (
                  <tr
                    key={index}
                    className="text-sm border-b border-gray-200 cursor-pointer hover:bg-gray-50"
                    
                  >
                    <td className="px-4 ">{goldLoan.loanid}</td>
                    <td className="px-4 ">{goldLoan.full_name}</td>
                    <td className="px-4 ">{goldLoan.amount}</td>
                    <td className="px-4 ">{goldLoan.trdate}</td>
                    <td className="px-4 ">{goldLoan.duedate}</td>
                    <td className="px-4 ">{goldLoan.gold_rate}</td>
                    <td className="px-4 ">{goldLoan.customerid}</td>
                    <td className="px-4 ">
                      {goldLoan.fpverified ? "Yes" : "No"}
                    </td>
                    <td className="px-4 ">{goldLoan.ucode}</td>
                    {/* <td className="px-4 "><button onClick={() => openModal(goldLoan)}>Details</button></td> */}

                    <td className="px-4 ">
                      <button onClick={()=>{setSelectedGoldLoan(goldLoan);setShowModal(true)}}>Approve</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* {selectedGoldLoan && (
          <Modal
            isOpen={isModal}
            closeModal={closeModal}
            goldLoan={selectedGoldLoan}
          />
        )} */}
      </div>
      <ApprovalModal
        isVisible={showModal}
        onClose={closeApproveModal}
        onConfirm={confirmApprove}
        // userId={userId}
        // setUserId={setUserId}
      />

      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
      />
    </>
  );
};

export default ApprovalGoldLoanList;
